import React from "react"
import { Link, graphql } from "gatsby"
import BasicLayout from "../components/layouts/Basic"
import styled from "styled-components"

const Post = styled.div`
  margin-bottom: 30px;
`

export default ({ data }) => {
  console.log(data)
  return (
    <BasicLayout>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Post key={node.id}>
          <Link to={node.fields.slug}>
            <h3>{node.frontmatter.title}</h3>
          </Link>
          <p>{node.frontmatter.date}</p>
          <p>{node.excerpt}</p>
        </Post>
      ))}
      <small>{data.allMarkdownRemark.totalCount} total posts</small>
    </BasicLayout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  }
`
